.btnVideo {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 15px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  display: block;
  border-radius: 15px;
  border: 1px solid #ff9280;
  color: black;
  position: relative;
  overflow: hidden;
  background: rgb(255, 36, 0);
  background: linear-gradient(
    148deg,
    rgba(255, 36, 0, 0.8) 0%,
    rgba(251, 82, 54, 0.8) 54%,
    rgba(252, 122, 100, 0.8) 70%,
    rgba(255, 149, 131, 0.8) 92%
  );
  transition: all 0.35s;
}

.btnVideo:before,
.btnVideo:after {
  position: absolute;
  content: "" !important;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: #ff2400 !important;
  z-index: 1;
  transition: all 0.35s;
}

.btnVideo:before {
  opacity: 0.5;
}

.btnVideo:after {
  transition-delay: 0.2s;
}

.btnVideo:hover {
  color: #fff;
}

.btnVideo:hover:before,
.btnVideo:hover:after {
  top: 0;
}
