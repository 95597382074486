.bg-images-shape {
  border-radius: 79% 21% 77% 23% / 53% 53% 47% 47%;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center2 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-hover:hover {
  transition: 0.6s;
  box-shadow: rgba(240, 46, 211, 0.4) 5px 5px, rgba(230, 46, 240, 0.3) 10px 10px,
    rgba(214, 46, 240, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(208, 46, 240, 0.05) 25px 25px;
}

.video-bg {
  background-color: #45315dce;
}
.center3 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
  .center2 {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 447px) {
  .center2 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }
}

.bg-slide {
  background-color: rgba(165, 120, 207, 0.377);
}

.org-bg {
  background: rgb(255, 36, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 36, 0, 1) 0%,
    rgba(245, 69, 40, 1) 37%,
    rgba(244, 103, 79, 1) 70%,
    rgba(255, 199, 190, 1) 100%
  );
}

.service-card {
  background: rgb(69, 49, 93);
  background: linear-gradient(
    148deg,
    rgba(69, 49, 93, 1) 0%,
    rgba(147, 103, 199, 1) 61%,
    rgba(177, 143, 217, 1) 93%
  );
  transition: 5s ease-in-out;
}
.service-card:hover {
  background: rgb(50, 28, 77);
  background: linear-gradient(
    148deg,
    rgb(49, 27, 75) 0%,
    rgb(125, 64, 195) 61%,
    rgb(160, 111, 215) 93%
  );
  transition: 4s ease-in-out;
}

.org-bg:hover {
  transition: 0.9s;
  background: rgb(255, 36, 0);
}

.centerone {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-image {
  height: 100vh;
  background-image: url(./images/bgOne.png);
  background-position: center;
  background-size: cover;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  height: 40px;
  --c: no-repeat linear-gradient(rgb(178, 0, 194) 0 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}
@keyframes l5 {
  0% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }
  33% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    width: 60px;
    height: 60px;
  }
  66% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    width: 60px;
    height: 60px;
  }
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

.sh {
  transition: 4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sh1 {
  transition: 4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sh1:hover {
  transition: 4s ease-in-out;
  box-shadow: rgb(165, 82, 184) 0px 20px 30px -10px;
}

.gr > *:nth-child(3n + 2) {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.gr > *:nth-child(-n + 3) {
  border-top: 1px solid gray;
}

@media (max-width: 768px) {
  .gr > *:nth-child(2n) {
    border-left: 0px;
    border-right: 1px solid gray;
  }
  .gr > *:nth-child(2n-1) {
    border-right: 0;
  }
}
@media (max-width: 640px) {
  .gr > *:nth-child(2n) {
    border-left: 0px;
    border-right: 0px;
  }
  .gr > *:nth-child(n) {
    border-left: 0px;
    border-right: 0px;
  }
  .gr > *:nth-child(2n-1) {
    border-right: 0px;
  }
}

.rotation {
  animation: spin 15s infinite;
}

.arrow-animation {
  position: relative;
  animation: arrowar 1.5s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes arrowar {
  0% {
    right: 0;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 10px;
  }
}

.bg-purpleLightOp {
  border: 1px solid #9467c76a;
}

.bg-img {
  background-image: url(./images/bgtransparent.png);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.contact-bg {
  opacity: 0.2;
  background-image: url(./images/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 100%;
}

.appbg {
  background: rgb(225, 197, 255);
  background: linear-gradient(
    148deg,
    rgba(225, 197, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 80%,
    rgba(225, 197, 255, 1) 100%
  );
}

.video-icon {
  animation: wallk 11.5s infinite;
}

@keyframes wallk {
  0% {
    bottom: -30px;
  }
  50% {
    bottom: 90%;
  }
  100% {
    bottom: -30px;
  }
}

/* LTR Styles */
.swiper-button-next-ltr::after {
  content: none;
  background-image: url(./images/arrow2rtl.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.swiper-button-prev-ltr::after {
  content: none;
  background-image: url(./images/arrowrtl.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important; /* Remove default arrow */
}

/* RTL Styles */
.swiper-button-next-rtl::after {
  content: none;
  background-image: url(./images/arrow2.png); /* Your RTL version */
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.swiper-button-prev-rtl::after {
  content: none;
  background-image: url(./images/arrow.png); /* Your RTL version */
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.input-bg {
  background-color: rgba(194, 138, 246, 0.301);
}

.bg-whiter {
  background-color: rgba(224, 223, 223, 0.352);
}

.transition-faq {
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.footer-bg {
  background-image: url(./images/footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 700px) {
  .footer-bg {
    background-image: url(./images/footer-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.purpleLightGradient {
  background: rgb(156, 104, 217);
  background: linear-gradient(
    90deg,
    rgba(156, 104, 217, 1) 0%,
    rgba(176, 136, 222, 1) 29%,
    rgba(191, 156, 232, 1) 60%,
    rgba(236, 228, 245, 1) 100%
  );
}

.secondSectionDescAr {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.secondSectionDescAr h1 {
  @apply text-xl sm:text-2xl lg:text-3xl;
  border-color: #45315d;
  border-right-width: 8px;
  padding-right: 20px;
  padding-top: 8px;
  border-radius: 0.375rem;
  padding-bottom: 8px;
  color: #45315d;
  font-weight: 600;
}
.secondSectionDescAr div {
  @apply sm:text-[1.125rem] lg:text-[1.25rem] leading-[40px];
  text-align: center;
  min-width: 100%;
  font-weight: 600;
  max-width: 60%;
  color: #616161;
  line-height: 2rem;
  font-weight: 400;
}
.secondSectionDescEn {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.secondSectionDescEn h1 {
  @apply text-xl sm:text-2xl lg:text-3xl;
  border-color: #45315d;
  border-left-width: 8px;
  padding-left: 20px;
  padding-top: 8px;
  border-radius: 0.375rem;
  padding-bottom: 8px;
  color: #45315d;
  font-weight: 600;
}
.secondSectionDescEn div {
  @apply sm:text-[1.125rem] lg:text-[1.25rem] leading-[40px];
  text-align: center;
  font-weight: 600;
  max-width: 60%;
  min-width: 100%;
  color: #616161;
  line-height: 2rem;
  font-weight: 400;
}

@media (min-width: 640px) {
  .secondSectionDescAr {
    margin-top: 0px;
  }
  .secondSectionDescAr div {
    text-align: start;
  }
}

.libSectionDesc_ar h1 {
  @apply text-purple font-medium text-2xl sm:text-3xl lg:text-4xl;
}
.libSectionDesc_en h1 {
  @apply text-purple font-medium text-2xl sm:text-3xl lg:text-4xl;
}
.libSectionDesc_ar div {
  @apply text-gray text-center lg:text-start sm:text-[1.125rem] lg:text-[1.25rem] leading-[40px];
}
.libSectionDesc_en div {
  @apply text-gray text-center lg:text-start sm:text-[1.125rem] lg:text-[1.25rem] leading-[40px];
}

.sliding-text-container {
  width: 100%; /* or set a specific width */
  overflow: hidden;
  white-space: nowrap; /* Prevent text from wrapping */
}

.sliding-text {
  display: inline-block;
  animation: slide 12s linear infinite; /* Adjust time for slower or faster sliding */
}
.sliding-text-en {
  display: inline-block;
  animation: slide_en 12s linear infinite; /* Adjust time for slower or faster sliding */
}

@keyframes slide {
  0% {
    transform: translateX(0%); /* Start outside from the right */
  }
  100% {
    transform: translateX(100%); /* Slide to the left */
  }
}

@keyframes slide_en {
  0% {
    transform: translateX(0%); /* Start outside from the right */
  }
  100% {
    transform: translateX(-100%); /* Slide to the left */
  }
}
