.container {
  position: relative;
  top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin-top: 60px;
  border-radius: 20px;
}

.card {
  position: relative;
  max-width: 280px;
  height: 195px;
  background-color: #fff;
  padding: 20px 30px;
  border: 1px solid rgba(206, 52, 206, 0.568);

  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}
.card:hover {
  height: 320px;
}

.image {
  border-radius: 20px;
  position: relative;
  width: 190px;
  height: 150px;
  top: -40%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  z-index: 1;
}

.img {
  border: 1px solid black;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.content {
  position: relative;
  top: -110px;
  padding: 5px 15px;
  color: #111;
  text-align: center;
  font-size: 18px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}
