@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap");

.text-title {
  @apply text-xl sm:text-2xl lg:text-3xl;
}

.text-f-title {
  @apply text-2xl sm:text-3xl lg:text-4xl;
}
.text-f-desc {
  @apply sm:text-[1.125rem] lg:text-[1.25rem] leading-[40px];
}
.text-viewAll {
  @apply sm:text-lg lg:text-xl;
}
